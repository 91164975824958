@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
* {
  font-family: 'Roboto';
  font-style: normal;
}

#root {
  background-color: var(--ds-elevation-surface-current);
  height: 100%;
}

.notification-description {
  margin-top: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;

  color: #172B4D;
}
.notification-description a {
  color: #105ccf
}

.notification {
  margin-bottom: 24.5px;
}

.notification:last-child {
  margin-bottom: 0;
}


.notification-title {
  font-weight: 500;
  font-size: 20px;
  color: '#172B4D';
  text-decoration: none;
  font-family: 'Roboto';
}

.notification-header-left-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.notification-body {
  width: 100%;
}

.notification:first-child .notification-category {
  display: inline-block;
}

.notification-category {
  /*display: inline-block;*/
  display: none;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  border-radius: 15px;
  padding: 4px 8px;
}

.notification-publication-date {
  /*margin-left: 12px;*/
  color: var(--ds-text, #6B778C);
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 24px;
}
