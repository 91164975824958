@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  padding: 0;
  margin: 0;
}

.ql-editor p:first-child {
  font-weight: 500;
  margin-bottom: 8px;
}

.ql-font-roboto p:not(first-child) {
  font-weight: 300;
}

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-font-segoeUI {
  font-family: 'Segoe UI', sans-serif;
}